<template>
    <div>
      <header><h1 class="title">中小学校“数字食堂”监管平台</h1></header>
      <div class="content-box">
        <aside class="left">
          <farming></farming>
          <food></food>
          <tradingCurve></tradingCurve>
        </aside>
        <aside class="right">
          <CountryTrade></CountryTrade>
          <turnover></turnover>
          <statistics></statistics>
        </aside>
        <section>
          <div class="total-box">
            <total></total>
          </div>
          <div class="map-svg">
            <areaMap areaId="420000" @change="handelClick"></areaMap>
          </div>
          <div class="bottom">
            <bottomTotal></bottomTotal>
          </div>
        </section>
      </div>
    </div>
  </template>
  <script>
  import farming from "@/components/farming";
  import food from "@/components/food";
  import tradingCurve from "@/components/tradingCurve";
  import turnover from "@/components/turnover";
  import statistics from "@/components/statistics"; 
  import total from "@/components/total";
  import bottomTotal from "@/components/bottomTotal";
  import areaMap from "@/components/areaMap";
  import CountryTrade from "@/components/countryTrade/index.vue";
  export default {
    name: "provincial",
    components: {
      farming,
      food,
      tradingCurve,
      turnover,
      statistics,
      total,
      bottomTotal,
      areaMap,
      CountryTrade
    },
    props: {},
    data() {
      return {
      };
    },
    methods: {
      handelClick({adCode,keyId}) {
        this.$router.push({
          path: "/city",
          query: {
            areaCode: adCode,
            city_id:keyId
          }
        });
      }
    }
  };
  </script>
  <style type="text/css"></style>
  