<template>
    <div>
        <header>
            <div class="sub-title" style="margin-top: 10px">
                <div class="sub-text">各区县交易情况统计</div>
                <p class="sub-text-en">
                    Statistics on the supply situation of each supplier
                </p>
            </div>
        </header>
        <div style="position: relative">
            <div class="problem-header clear-float">
                <span class="problem-item">序号</span>
                <span class="problem-item">地区</span>
                <span class="problem-item">笔数</span>
                <span class="problem-item">发货金额</span>
                <span class="problem-item">收货金额</span>
            </div>
            <Spin size="large" fix v-if="loading"></Spin>
            <vue-seamless-scroll
                :class-option="option"
                :data="infoData"
                class="seamless-warp"
            >
                <ul>
                    <li
                        class="clear-float"
                        v-for="(item, index) in infoData"
                        :key="item.index"
                    >
                        <span class="problem-item"
                            ><span class="radius">{{ index + 1 }}</span></span
                        >
                        <span class="problem-item">{{ item.area_txt }}</span>
                        <span class="problem-item">{{
                            item.order_count || "-"
                        }}</span>
                        <span class="problem-item">{{
                            item.shipping_money || "-"
                        }}</span>
                        <span class="problem-item">{{
                            item.reciver_money || "-"
                        }}</span>
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>
    </div>
</template>
<script>
import { areaAmtApi } from "@/api";
export default {
    name: "statistics",
    components: {},
    props: {},
    data() {
        return {
            loading: false,

            infoData: [],
        };
    },
    computed: {
        option() {
            return {
                step: 0.3,
            };
        },
    },
    mounted() {
        this.init();
        this.initData();
    },
    methods: {
        async initData() {
            try {
                this.loading = true;
                const staticData = {};
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }

                const resData = await areaAmtApi(staticData);
                this.infoData = resData.data;
            } finally {
                this.loading = false;
            }
        },
        init() {
            this.infoData.map(function (currentValue, index, arr) {
                (currentValue.distributor = currentValue.distributor + "家"),
                    (currentValue.cooperative =
                        currentValue.cooperative + "家"),
                    (currentValue.farmer = currentValue.farmer + "户");
            });
        },
    },
};
</script>
<style type="text/css" scoped>
.seamless-warp {
    height: 160px;
}
</style>
