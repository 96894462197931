<template>
    <div class="map-box">
        <Spin size="large" fix v-if="loading"></Spin>
        <div
            id="china-map"
            ref="chinaMap"
            @mouseleave="showfixBox = false"
            @mouseenter="showfixBox = false"
            @mouseover="changeposition"
            @mousemove="changeposition"
        ></div>
        <div
            class="total-hover"
            :style="`top:${topfixed}px;left:${leftFixed}px`"
            ref="mapframeref"
            v-show="showfixBox"
        >
            <MapFrame :mapObj="mapObj"></MapFrame>
        </div>
    </div>
</template>
<script>
let echarts = require("echarts/lib/echarts");
import MapFrame from "./mapframe.vue";
import axios from "axios";
import { mapApi } from "@/api";

export default {
    name: "AreaMap",
    components: { MapFrame },
    props: {
        areaId: { type: String },
    },
    data() {
        return {
            option: null,
            series: null,
            map: null,
            dataTemp: [],
            projects: [],
            selectArea: [],
            infoData: {},
            loading: false,
            topfixed: 0,
            leftFixed: 0,
            showfixBox: false,
            mapObj: {},
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        changeposition(e) {
            var scalechange = document.body.clientWidth / 1920;
            this.leftFixed = (e.clientX + 100) / scalechange;
            this.topfixed = e.clientY / scalechange;
            if (this.topfixed < 0) {
                this.topfixed = 0;
            }
        },
        async initData() {
            try {
                this.loading = true;
                const staticData = {};
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }
                const resData = await mapApi(staticData);
                this.infoData = resData.data;

                resData.data.push({ unit_name: "丹江口市" });
                this.selectArea = this.infoData.map((item) => {
                    return {
                        name: item.unit_name,
                        label: {
                            normal: {
                                show: true,
                                textStyle: {
                                    color: "aqua",
                                },
                            },
                            emphasis: {
                                textStyle: {
                                    color: "aqua",
                                },
                            },
                        },
                        itemStyle: {
                            normal: {
                                areaColor: "#1A1AD4",
                            },
                            emphasis: {
                                areaColor: "#0040FF",
                            },
                        },
                    };
                });

                this.setOption();
                this.init();
            } finally {
                this.loading = false;
            }
        },
        init() {
            
            axios
                .get(
                    `/hbJson/${this.areaId}.geoJson`
                )
                .then((response) => {
                    this.setMap(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        addText(params, api, realData) {
            return {
                type: "text",
                style: {
                    text: this.dataTemp[params.dataIndex].stationname,
                    font: "18px AlibabaPuHuiTi-Bold",
                    fill: "#FFFFFF",
                    x:
                        api.coord([
                            realData[params.dataIndex].lng,
                            realData[params.dataIndex].lat,
                        ])[0] -
                        66 +
                        16,
                    y:
                        api.coord([
                            realData[params.dataIndex].lng,
                            realData[params.dataIndex].lat,
                        ])[1] -
                        70 +
                        13,
                },
            };
        },
        setOption() {
            this.option = {
                geo: {
                    map: "chinaMap",
                    roam: false,
                    zoom: 1.17,
                    layoutSize: "100%",
                    label: {
                        normal: {
                            show: true,
                            textStyle: {
                                color: "#6699FF",
                            },
                        },
                        emphasis: {
                            textStyle: {
                                color: "#6699FF",
                            },
                        },
                    },
                    itemStyle: {
                        normal: {
                            areaColor: "#05328A",
                            borderColor: "#337FD6",
                            borderType: "solid",
                            borderWidth: 1, //设置外层边框
                        },
                        emphasis: {
                            areaColor: "#05328A", //鼠标移入颜色
                        },
                    },
                    z: 3,
                    regions: this.selectArea,
                },
                series: [
                    {
                        type: "map",
                        map: "chinaMap",
                        roam: false, // 一定要关闭拖拽
                        zoom: 1.17, //当前视角的缩放比例
                        layoutSize: "100%",
                        label: {
                            show: true,
                            normal: {
                                show: true,
                                textStyle: {
                                    color: "#fff",
                                },
                            },
                            emphasis: {
                                textStyle: {
                                    color: "#fff",
                                },
                            },
                        },
                        itemStyle: {
                            normal: {
                                areaColor: "#001437",
                                borderColor: "aqua",
                                borderType: "solid",
                                borderWidth: 4, //设置外层边框
                                shadowColor: "rgba(20,208,228,0.5)",
                                shadowBlur: 40,
                            },
                            emphasis: {
                                areaColor: "#14E4CC",
                            },
                        },
                    },
                ],
            };
        },
        setMap(data) {
            echarts.registerMap("chinaMap", data);
            let map = echarts.init(document.getElementById("china-map"));
            map.setOption(this.option);
            map.on("click", (params) => {
                const findObj = data.features.find(
                    (item) => item.properties.name === params.name
                );
                const adCode = findObj.properties.adcode;
                const idCodeObj = this.infoData.find(
                    (item) => item.unit_name == params.name
                );
                if (!this.$route.query.city_id) {
                    this.$emit("change", { adCode, keyId: idCodeObj.city_id });
                }else{
                    this.$emit("change", { adCode, keyId: idCodeObj.area_id });
                }
              
            });
            map.on("mouseover", (e) => {
                const idCodeObj = this.infoData.find(
                    (item) => item.unit_name == e.name
                );

                if (idCodeObj) {
                    this.mapObj = idCodeObj;
                    this.showfixBox = true;
                } else {
                    this.mapObj = {};
                    this.showfixBox = false;
                }
            });
            window.addEventListener("resize", function () {
                setTimeout(() => {
                    map.resize();
                }, 1000);
            });
        },
    },
};
</script>

<style scoped>
.map-box {
    width: 730px;
    height: 627px;
}
#china-map {
    width: 100%;
    height: 100%;
}
.total-hover {
    position: fixed;
    z-index: 9;
}
</style>
