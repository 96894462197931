<template>
    <div class="mapframe">
        <div>
            <div class="bgcRotted">
                <div class="titleframe">{{ mapObj.unit_name }}</div>
                <div class="countmaoexpo">
                    <div class="smallfTitle">累计订单数</div>
                    <div class="countStyleYear">
                        <countTo
                            :decimals="0"
                            :startVal="0"
                            :endVal="parseFloat(mapObj.order_count)"
                            :duration="1000"
                        ></countTo>
                    </div>
                </div>
                <div class="flexDataInlie">
                    <div class="itemframe" style="width: 50%">
                        <div class="smallfTitle">收货金额</div>
                        <div>
                            <countTo
                                :decimals="decimals"
                                :startVal="0"
                                :endVal="parseFloat(mapObj.reciver_money)"
                                :duration="1000"
                            ></countTo>
                            <span class="unitd">元</span>
                        </div>
                    </div>
                    <div class="itemframe" style="width: 50%">
                        <div class="smallfTitle">发货金额</div>
                        <div>
                            <countTo
                                :decimals="decimals"
                                :startVal="0"
                                :endVal="parseFloat(mapObj.shipping_money)"
                                :duration="1000"
                            ></countTo
                            ><span class="unitd">元</span>
                        </div>
                    </div>
                </div>
                <div class="centerline"></div>
                <div class="flexDataInlie">
                    <div class="itemframe">
                        <div class="smallfTitle">供货商数</div>
                        <div>
                            <countTo
                                :startVal="0"
                                :endVal="parseFloat(mapObj.merchant_count)"
                                :duration="1000"
                            ></countTo>
                        </div>
                    </div>
                    <div class="itemframe">
                        <div class="smallfTitle">覆盖学校数</div>
                        <div>
                            <countTo
                                :startVal="0"
                                :endVal="parseFloat(mapObj.entity_count)"
                                :duration="1000"
                            ></countTo>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "total",

    props: {
        mapObj: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            decimals: 2,
            daley: 4000,
        };
    },
};
</script>
<style lang="scss">
.countStyleYear {
    margin: 8px 0px;
    font-size: 16px;
    text-align: left;
    font-family: makinas;
    font-weight: normal;
    color: #ffdb67;
    line-height: 26px;
    text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.4);
}
.countStyleYear .numbers {
    width: 14px;
    height: 20px;
    vertical-align: middle;
    line-height: 20px;
    font-size: 16px;
    font-family: makinas;
    font-weight: normal;
    color: #ffffff;
    margin-left: 1px;
    text-overflow: center;
    display: inline-block;
    text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.4);
    background: #17263b;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.4);
}
.countStyleYear .dotnumber {
    font-family: makinas;
    font-weight: normal;
    margin-left: 0px;
    color: #ffffff;
    line-height: 18px;
    text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.4);
}
.centerline {
    width: 6px;
    height: 1px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.4);
    border: 1px solid #ffdb67;
}
.flexDataInlie {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.mapframe {
    width: 248px;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 251, 0.7) 0%,
        rgba(0, 0, 251, 0.4) 100%
    );

    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.4);
}
.bgcRotted {
    padding: 16px 6px 25px 10px;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, #00feff, #00feff) no-repeat left top,
        linear-gradient(to left, #00feff, #00feff) no-repeat right top,
        linear-gradient(to left, #00feff, #00feff) no-repeat left bottom,
        linear-gradient(to left, #00feff, #00feff) no-repeat right bottom,
        transparent;
    background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px;
}
.titleframe {
    font-size: 14px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #add1df;
    margin-bottom: 8px;
    text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.4);
}
.smallfTitle {
    font-size: 12px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    text-align: left;
    line-height: 18px;
    text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.4);
}
.itemframe {
    margin-bottom: 20px;
    text-align: left;
    width: 33%;
}
.itemframe span {
    font-size: 12px;
    font-family: makinas;
    font-weight: normal;
    color: #ffdb67;
    margin-top: 4px;
    line-height: 18px;
    text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.4);
}
.unitd {
    font-size: 12px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.4);
}
.itemframe .unitd {
    font-size: 12px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.4);
}
</style>
